<template>
  <div>
    <responsive-menu ref="menu"
                     :title="selectedItemName"
                     :opcoes="menuOptions"
                     @click:item="action => $emit(`click:${action}`, selectedItem)"/>
    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  @click:row="onClickRow"
                  @contextmenu:row.prevent="showMenu"
                  :footer-props="{
                    disableItemsPerPage: true,
                    itemsPerPageOptions: [itemsPerPage],
                  }"
                  class="custom-data-table"
                  :items="data" :loading="loading">
      <template v-slot:item.id="{item, index}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.edit="{item}">
        <v-btn @click.prevent="showMenu($event, item)" small icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-card v-if="items.length === 0" class="elevation-0">
          <v-card-text class="text-center">
            <p>Nenhum Responsável disponível.</p>
          </v-card-text>
        </v-card>
        <v-list dense class="pa-0">
          <v-list-item @click="showMenu($event, item)" v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{item.nome}}/{{item.login}}</v-list-item-title>
              <v-list-item-subtitle>{{item.cargo}}/{{item.celular}}/{{item.email}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import ResponsiveMenu from "../../app/ResponsiveMenu";
import {mapGetters} from "vuex";

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "ContatoDataTable",
  components: {ResponsiveMenu},
  props: {
    elements: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      data: [],
      selectedItem: null,
      menuOptions: [
        {
          action: 'edit',
          text: 'Editar',
          icon: 'mdi-pencil',
          permiteAcao: false,
        },
        {
          action: 'remove',
          text: 'Remover',
          icon: 'mdi-delete',
          color: 'error',
          permiteAcao: false,
        }
      ],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      headers: [
        // {
        //   value: 'id',
        //   text: 'ID',
        //   align: 'start',
        //   width: 100
        // },
        {
          text: 'Nome',
          value: 'nome',
          align: 'start'
        },
        // {
        //   text: 'Login',
        //   value: 'login',
        //   align: 'start'
        // },        
        {
          text: 'Cargo',
          value: 'cargo',
          align: 'start'
        },
        {
          text: 'Celular',
          value: 'celular',
          align: 'start'
        },
        {
          text: 'E-mail',
          value: 'email',
          align: 'start'
        },
        {
          text: 'Menu',
          width: 30,
          value: 'edit',
          align: 'end'
        }
      ],
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),    
    selectedItemName() {
      return this.selectedItem?.nome
    },
  },
  created() {
    console.log('Dados recebidos no ContatoDataTable:', this.elements);
  },
  watch: {
    elements: {
      handler(newVal) {
        this.refresh();
      },
      deep: true
    },
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.$refs.menu.open(event);
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    refresh() {
      try {
        console.log('vai atualizar')
        this.error = null;
        this.loading = true;
        this.data = this.elements;
        this.itemsPerPage = 5;
        this.totalElements = this.elements.length;
        this.pageOptions.page = 1;

      } catch (err) {
        this.$toast.error(`Não foi possível carregar a lista de Responsáveis.`, {
          timeout: 6000
        })
      } finally {
        this.loading = false;
      }
    },
    async getPermissoes(){
      this.menuOptions[0].permiteAcao = await this.getPermissaoPorItemPerfil("Contato.update",this.nivelUsuarioLogado);
      this.menuOptions[1].permiteAcao = await this.getPermissaoPorItemPerfil("Contato.exclui",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">
  .list-item {
    &::v-deep {
      .v-list-item__content {
        max-width: calc(100vw - 128px);
      }
    }
  }
  .list-item:not(:last-child) {
    border-bottom: 1px solid #dedede
  }

  ::v-deep .custom-data-table .v-data-table__wrapper .v-data-table-header th {
    color: #1374B9 !important;
  }

  // ::v-deep .custom-data-table .v-data-table__wrapper tr:nth-child(odd) {
  //   color: white;
  //   background-color: #2EAB66; /* Cor para linhas ímpares */
  //   padding: 2px;
  // }

  // ::v-deep .custom-data-table .v-data-table__wrapper tr:nth-child(even) {
  //   background-color: #ffffff; /* Cor para linhas pares */
  //   padding: 2px;
  // }

  ::v-deep .custom-data-table .v-data-table__wrapper tr:hover,
  ::v-deep .custom-data-table .v-data-table__wrapper .list-item:hover {
    color: white !important;
    background-color: #2EAB66 !important; /* Cor ao passar o mouse */
  }

  ::v-deep .custom-data-table .v-data-table__wrapper tr:hover .v-icon,
  ::v-deep .custom-data-table .v-data-table__wrapper .list-item:hover .v-icon {
    color: white !important; /* Mudar a cor do ícone para branco */
  }
</style>