var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"* Nome","dense":"","rules":[
                  this.$validators.string.required,
                  function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                  function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.nome),callback:function ($$v) {_vm.$set(_vm.value, "nome", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.nome"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Cargo","dense":"","rules":[
                  this.$validators.string.required,
                  function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                  function (v) { return this$1.$validators.string.lessThanOrEquals(v, 100); } ]},model:{value:(_vm.value.cargo),callback:function ($$v) {_vm.$set(_vm.value, "cargo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cargo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"label":"CPF","dense":"","placeholder":"000.000.000-00","rules":[
                      this.$validators.string.cpf
                    ]},model:{value:(_vm.value.cpf),callback:function ($$v) {_vm.$set(_vm.value, "cpf", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.cpf"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"* Email","dense":"","rules":[
                      this.$validators.string.required,
                      this.$validators.string.email,
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 50); } ]},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"label":"* Celular","dense":"","rules":[
                      this.$validators.string.required ]},model:{value:(_vm.value.celular),callback:function ($$v) {_vm.$set(_vm.value, "celular", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.celular"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) ####-####'),expression:"'(##) ####-####'"}],attrs:{"label":"Telefone","dense":""},model:{value:(_vm.value.telefone),callback:function ($$v) {_vm.$set(_vm.value, "telefone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.telefone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Data Nascimento","dense":"","type":"date"},model:{value:(_vm.value.dataNascimento),callback:function ($$v) {_vm.$set(_vm.value, "dataNascimento", $$v)},expression:"value.dataNascimento"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }