<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="1200px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title class="primary white--text">
      {{getTitle}}
      <v-spacer/>
      <v-btn text elevation="0" color="white" small @click="$emit('input', false)">
        Fechar <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">

      <v-form ref="form" @submit.prevent="preSave" v-model="formValid" lazy-validation>
        <ContatoForm v-model="item"/>
      </v-form>

    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="success" @click="$emit('input', false)">Cancelar</v-btn>
      <v-btn color="primary" @click="preSave" :disabled="!formValid">{{getAcao}}</v-btn>      
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import ContatoForm from "../forms/ContatoForm";
import {ApiError} from "../../../api/core";
export default {
  name: "CreateEditContatoDialog",
  components: {ContatoForm},
  props: ['value','item'],
  data () {
    return {
      formValid: false
    }
  },
  computed: {
    getTitle() {
      return (parseInt(this.item?.id) !== 0 ? 'Atualizar' : 'Adicionar') + ' Responsável';
    },
    getAcao(){
      return (parseInt(this.item?.id)  !== 0 ? 'Atualizar' : 'Adicionar') + ' Responsável';
    }
  },
  methods: {
    preSave() {
      try {
        if(this.$refs.form.validate()) {
          this.$emit(`click:save`, this.item);
        } else {
          this.$toast.error(`Informe todos os campos obrigatórios`, {
            timeout: 6000
          })
        }
      } catch (err) {
        this.$toast.error(`Ocorreu um erro ao ${this.item.id ? 'atualizar' : 'adicionar'} o Responsável.`, {
          timeout: 6000
        })
      }
    }
  },
}
</script>

<style scoped>

</style>